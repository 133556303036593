import React, { useEffect, useState } from 'react';
import { MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineTouchApp, MdOutlineWatchLater } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal";
import { DeletePopupModal, EditDp, FormParser, PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import { UpgradePage } from "./UpgradePage";
import { Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { CraftsModal } from './CraftsModal';
import { GrAlarm } from 'react-icons/gr';
import checkProRealtime from '../Utils/checkProRealtime';
import { ContactModal } from './ContactModal';
import projectTypeUtils from '../Utils/ProjectTypecolors';
import { closeRequirement } from '../Utils/ApiHandles';
import { Requirementcontent } from './Requirementcontent';
import { isButtonDisabled } from '../Utils/RoleCheckService';
import { copyRequirement, shareRequirement } from '../Utils/CheckNavigation';

const AudirequireShare = ({ Audition, index, HandleDeleteClose }) => {
    const Navigate = useNavigate();
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [loading, setLoading] = useState(false);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [deletePopupType, setDeletepopupType] = useState("");
    const [RowId, SetRowId] = useState();
    const [AlertDeletePopupType1, SetAlertDeletePopupType1] = useState("");
    const [deletePopupType1, setDeletepopupType1] = useState("");
    const [RowId1, SetRowId1] = useState();
    const [UpgradeShow, setUpgradeShow] = useState(false);
    const [ScreenVisbile, setScreenVisbile] = useState("plans");
    const [auditionRequirements, setAuditionRequirements] = useState([]);
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const [show, setShow] = useState(false);
    const [popupType, setpopupType] = useState("");
    const [Message, setMessage] = useState("")
    const handleUpgradeClose = () => {
        setUpgradeShow(false);
        setScreenVisbile("plans");
    };

    const HandleUpgrade = () => {
        setUpgradeShow(true);
    };

    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    };

    const DeleteAlert = (id) => {
        SetRowId(id);
        SetAlertDeletePopupType("success");
    };

    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error");
    };

    const HandleDelete = (id) => {
        console.log(id);
        axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res);
            setDeletepopupType("success");
            SetAlertDeletePopupType("error");
            HandleDeleteClose();
        }).catch(error => {
            console.log(error);
        });
    };
    const handleprofilpicclose = () => {
        setProfilepic(false)
    }
    const handleClose = () => {
        setshowApply(false)
    }
    const HandleProfilepic = () => {
        if (profile_data?.profile_pic === null) {
            setProfilepic(true)
        } else if (localStorage.getItem('is_24_craft') === null) {
            setshowApply(true)
        }
        else {
            HandleUpgrade();
        }
    }
    const DeleteAlert1 = (id) => {
        if (localStorage.getItem('token')) {
            SetRowId1(id);
            if (localStorage.getItem('is_pro') === "true") {
                const checkCondition = async () => {
                    await checkProRealtime.checkCondition();
                };
                checkCondition();
                SetAlertDeletePopupType1("success");
            } else {
                HandleProfilepic()
            }
        } else {
            handleLoginShow();
        }
    };

    const CancelAlerrt1 = () => {
        SetAlertDeletePopupType1("error");
    };
    const handleApply = (id) => {
        axiosConfig.post("/accounts/audition-applications/", { audition_requirement: id }).then(res => {
            console.log(res);
            fetchAuditionList(profile_data)
            setDeletepopupType1("success");
            SetAlertDeletePopupType1("error");
        }).catch(error => {
            console.log(error);
        });
    };
    const handleNavigate = (id, type) => {
        if (type === "view") {
            Navigate(`/auditions/${id}`);
        } else {
            Navigate(`/auditions/${id}/edit`);
        }
    };
    const fetchAuditionList = async (profile_data, id) => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/?profile=${profile_data?.profile_id}&audition_requirement=${Audition?.id}&application_status=`);
            const auditionRequirements = response.data.results.map(item => item.audition_requirement);
            setAuditionRequirements(auditionRequirements);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        if (daysAgo > 0) return `${daysAgo}d${daysAgo > 1 ? '' : ''} ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    const [Data, setData] = useState({})
    const [requirementShow, setRequirementShow] = useState(false)
    const HandleEdit = (id) => {
        setData({})
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            setData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const AudiRequirements = (data, setFieldErrors) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: Data.audition?.id,
            approval_status: "requested"
        }
        if (data?.from_age >= data?.to_age) {
            let errorMessage = "From age must be less than To age."
            setFieldErrors(prev => ({ ...prev, ['from_age']: errorMessage, ['to_age']: errorMessage }));
        } else {
            axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderTopost).then(res => {
                console.log(res)
                HandleDeleteClose()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
            })
        }
    }
    const RequirementClose = async (Audi_id, statustype) => {
        await closeRequirement(Audi_id, statustype, setpopupType, setMessage);
    }
    const handleShow = () => {
        if (localStorage.getItem('token')) {
            console.log(localStorage.getItem("is_pro"))
            if (localStorage.getItem('is_pro') === "true") {
                setShow(true);
            }
            else {
                HandleUpgrade()
            }
        } else {
            handleLoginShow();
        }
    }
    useEffect(() => {
        if (profile_data) {
            fetchAuditionList(profile_data)
        }
    }, [Audition])
    return (
        <>
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="card card-style" style={{ backgroundColor: projectTypeUtils.getProjectType(Audition.audition?.project_type) }}>
                    <div className="card-type card-types" style={{ backgroundColor: projectTypeUtils.getProjectTypeColor(Audition.audition?.project_type) }}>{Audition.audition?.project_type ? Audition.audition?.project_type : "---"}</div>
                    <div className="dropdown audi-edit">
                        <MdOutlineMoreVert data-bs-toggle="dropdown" size={25} />
                        <ul className="dropdown-menu">
                            {Audition?.audition?.access_controls?.some(accesscontrol => accesscontrol?.profile?.id === profile_data?.profile_id) &&
                                <>
                                    {Audition?.audition?.access_controls?.some(accesscontrol => accesscontrol?.profile?.id === profile_data?.profile_id && accesscontrol.access_type !== "viewer") &&
                                        <>
                                            {Audition?.requirement_status === "live" &&
                                                <>
                                                    {/* <li><a className="dropdown-item" onClick={() => { HandleEdit(Audition.id) }}>edit</a></li> */}
                                                    <li><a className="dropdown-item" onClick={() => DeleteAlert(Audition?.id)}>Delete</a></li>
                                                    <li><a className="dropdown-item" onClick={() => RequirementClose(Audition?.id, "closed")}>Close</a></li>
                                                </>
                                            }
                                            {Audition?.requirement_status === "closed" &&
                                                <>
                                                    <li><a className="dropdown-item" onClick={() => RequirementClose(Audition?.id, "live")}>Open</a></li>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <li><a className="dropdown-item" onClick={() => copyRequirement(Audition)}>Copy URL</a></li>
                            <li><a className="dropdown-item" onClick={() => shareRequirement("share", Audition)}>Share</a></li>
                        </ul>
                    </div>
                    <div className='card-content'>
                        {Audition?.audition?.banner_name ? (
                            <h4 className="banner-title">
                                {Audition?.audition?.banner_name}
                            </h4>
                        ) : (
                            <div style={{ padding: '15px' }}></div>
                        )}
                        <div className="card-descrt">
                            <div onClick={() => handleNavigate(Audition?.audition.id, "view")} className="card-descrt-inner">
                                <div style={{ color: "#000" }} className="project_title">
                                    <div className="audi-id">RD{Audition?.id && Audition?.id}</div>
                                    <div>
                                        <div className='Audi-project-title'>{Audition?.audition?.project_title && Audition?.audition?.project_title}</div>
                                        <div className='Audi-description'>{Audition?.audition?.description && Audition?.audition?.description}</div>
                                    </div>
                                </div>
                                <div className="card-audi">
                                    {Audition?.audition?.platform &&
                                        <div className="audi-label" style={{ background: '#b00de9' }}>{Audition?.audition?.platform}</div>
                                    }
                                    {Audition?.audition?.zoner &&
                                        <div className="audi-label" style={{ background: '#671640' }}>{Audition?.audition?.zoner}</div>
                                    }
                                    {Audition?.audition?.loc_lang?.city &&
                                        <div className="audi-label" style={{ background: '#3cb371' }}>{Audition?.audition?.loc_lang?.city}</div>
                                    }
                                    {Audition?.audition?.loc_lang?.languages &&
                                        <div className="audi-label" style={{ background: '#db7093' }}>{Audition?.audition?.loc_lang?.languages}</div>
                                    }
                                </div>
                                <div className="card-detail">
                                    <div className={`card-botton-centent ${Audition.audition?.hero ? 'align-items-start' : 'align-items-start'}`}>
                                        <div className="card-cont-title">Producer</div>
                                        <div className='card-cont-detail'>{Audition.audition?.producer_name ? Audition.audition?.producer_name : "-"}</div>
                                    </div>
                                    <div className={`card-botton-centent ${Audition.audition?.hero ? 'align-items-center' : 'custom-chnage-dir'}`}>
                                        <div className="card-cont-title">Director</div>
                                        <div className='card-cont-detail'>{Audition.audition?.director_name ? Audition.audition?.director_name : "-"}</div>
                                    </div>
                                    {Audition.audition?.hero && <div className="card-botton-centent custom-chnage-dir">
                                        <div className="card-cont-title">Hero</div>
                                        <div className='card-cont-detail'>{Audition.audition?.hero ? Audition.audition?.hero : "-"}</div>
                                    </div>}
                                </div>
                                <div className="">
                                    <div className="card-title-custom">Role - {Audition.character}</div>
                                    <Requirementcontent Audition={Audition} />
                                </div>
                            </div>
                            <div className="card-bottom">
                                <div className="card-bottom-lefts">
                                    <div className="card-bottom-detail"><GrAlarm size={14} /><span style={{ marginLeft: "5px" }}>{timeAgo(Audition?.created_at)}</span></div>
                                    <div className="card-bottom-detail"><MdOutlineRemoveRedEye size={14} /><span style={{ marginLeft: "5px" }}>{Audition?.audition?.views}</span></div>
                                    <div className="card-bottom-detail" style={{ color: "#212529" }}>Applications <span style={{ marginLeft: "5px" }}> ({Audition?.applications})</span></div>
                                </div>
                                <div className="view-button-profile">
                                    <div className="d-flex justify-content-between gap-3">
                                        {Audition?.audition?.profile?.id === profile_data?.profile_id ? (
                                            <>
                                                <button type="button" className={`btn w-50 ${Audition.requirement_status === "closed" ? "btn-secondary" : "btn-primary"}`} onClick={() => RequirementClose(Audition.id, Audition.requirement_status === "closed" ? "live" : "closed")}>{Audition.requirement_status === "closed" ? "Re Open" : "Close"}</button>
                                                <button type="button" className="btn btn-primary w-50" onClick={() => handleNavigate(Audition?.audition.id, "view")}>View</button>
                                            </>
                                        ) : (
                                            Audition.requirement_status === "closed" ? (
                                                <button type="button" className="btn btn-secondary w-100" disabled>Audition Closed</button>
                                            ) : (
                                                auditionRequirements?.includes(Audition.id) ? (
                                                    <>
                                                        <button type="button" className="btn btn-primary w-50" onClick={handleShow}>Contact</button>
                                                        <button type="button" className="btn btn-primary w-50" disabled>Applied</button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button type="button" className="btn btn-primary w-50" onClick={handleShow}>Contact</button>
                                                        <button type="button" className="btn btn-primary w-50" onClick={() => DeleteAlert1(Audition.id)} disabled={isButtonDisabled(Audition)}>Apply</button>
                                                    </>
                                                )
                                            )
                                        )}
                                    </div>
                                    <p className='contact_query'> <span>NOTE:</span> Any queries, contact the HCC office no 7799234563 </p>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            {show &&
                <ContactModal AudiData={Audition?.audition} show={show} setShow={setShow} />
            }
            {
                UpgradeShow &&
                <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} RowId1={RowId1} />
            }
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Delete Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {
                deletePopupType1 !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType1} deletePopupType={deletePopupType1} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType1 === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType1} SetAlertDeletePopupType={SetAlertDeletePopupType1} HandleDelete={handleApply} CancelAlerrt={CancelAlerrt1} row={RowId1} message={"Are You Sure You Want Apply for this Audition Requirement ?"} />
            }
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => setRequirementShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                    {Data.id && <div class="alert alert-danger">If you change requirement it will be temporarily removed from live until admin approves the it</div>}
                </Modal.Body>
            </Modal>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add your profile to get started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                </Modal.Body>
            </Modal>
            {
                showApply &&
                <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
            }
            {
                popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} successmsg={Message} HandleEvents={HandleDeleteClose} />
            }
        </>
    );
};

export { AudirequireShare };
