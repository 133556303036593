import React, { useState } from "react";
import "./Post.css"
import { Shortlist } from "../ShortList/ShortList";
import { hasRelevantRole } from "../../Utils/RoleCheckService";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiMap } from "react-icons/bi";

const ActionsDp = ({ item }) => {
    const frames_24 = localStorage.getItem('frames_24');
    return (
        <>
            <dpostiv className="actionsdp-list">
                {hasRelevantRole(frames_24) &&
                    <>
                        <p className="ellipse_container"><BiMap size={16} /><span className="textellipse">{item?.present_city ? item?.present_city : item?.state_city?.city}</span></p>
                        <Shortlist ShortlistId={item.id} />
                    </>
                }
            </dpostiv>
        </>
    )
}
export { ActionsDp };