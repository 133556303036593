export const hasRelevantRole = (rolesString) => {
    const relevantRoles = [
        "Executive Producer",
        "Casting Director",
        "Producer",
        "Director",
        "Associate Director",
        "Co-Director",
        "Assistant Director",
        "Production Manager"
    ];

    return rolesString
        ?.split(',')
        ?.some((role) => relevantRoles.includes(role.trim()));
};
export const hasArtisticRole = (rolesString) => {
    const artisticRoles = [
        "Artist",
        "Model",
        "Dancer"
    ];

    return rolesString
        ?.split(',')
        ?.some((role) => artisticRoles.includes(role.trim()));
};
export const isButtonDisabled = (audition) => {
    const token = localStorage.getItem("token")
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const profileGender = profile_data?.gender
    if (!token) {
        return false;
    }
    let eligibleGenders;
    if (profileGender === "male") {
        eligibleGenders = ["male", "male and female"];
    } else if (profileGender === "female") {
        eligibleGenders = ["female", "male and female"];
    } else if (profileGender === "other") {
        eligibleGenders = ["other"];
    }
    if (!eligibleGenders?.includes(audition?.gender)) {
        return true;
    }
    return false
};
