import React, {  useRef } from "react";
import { Topbar, Navbar, Categories, AdRight, AdPost, Footer, Stories } from "../Components";
import { NavLink, Outlet } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import CustomTop from "./CustomTop";
import "./Pages.css"
import { SuggestedUsers } from "./SuggestedUsers";
const Home = () => {
    const footerRef = useRef(null);

    return (
        <>
            <CustomTop />
            <div className="main-container homescreen">
                <Navbar />
                <div className="content-area content-areahome">
                    <div className="stories-container">
                        <Stories />
                    </div>
                    <div className="posting-container">
                        <PostsPage footerRef={footerRef}/>
                    </div>
                </div>
                {/* <div className="right-area">
                    <div className="suggestions-area">
                        <AdRight />
                        <AdPost />
                    </div>
                    <div className="suggestions-users">
                        <SuggestedUsers />
                    </div>
                </div> */}
            </div>
            <Footer ref={footerRef}/>
        </>
    )

}
export { Home }