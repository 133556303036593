import React from 'react'

const Requirementcontent = ({ Audition }) => {
    return (
        <>
            <div className="applictn-dtls">
                {Audition?.gender && (
                    <div className="row">
                        <div className="apply-content col-6"><span>Gender</span><span>:</span></div>
                        <div className="col-6 apply-data">{Audition.gender}</div>
                    </div>)}
                <div className="row">
                    <div className="apply-content col-6"><span>Age</span><span>:</span></div>
                    <div className="col-6 apply-data">{Audition.from_age} - {Audition.to_age} years</div>
                </div>
                {Audition?.budget &&
                    <div className="row">
                        <div className="apply-content col-6"><span>Budget</span><span>:</span></div>
                        <div className="col-6 apply-data">{Audition.budget ? Audition.budget : 0}</div>
                    </div>}
                {Audition?.no_of_requirements &&
                    <div className="row">
                        <div className="apply-content col-6"><span>No. of Positions</span><span>:</span></div>
                        <div className="col-6 apply-data">{Audition.no_of_requirements}</div>
                    </div>}
                <div className="row">
                    <div className="apply-content col-6"><span>Preffered Languages</span><span>:</span></div>
                    <div className="col-6 apply-data">{Audition?.loc_lang?.languages ? Audition?.loc_lang?.languages : "Any Language"}</div>
                </div>
                <div className="row">
                    <div className="apply-content col-6"><span>Preffered Location</span><span>:</span></div>
                    <div className="col-6 apply-data">{Audition?.loc_lang?.city
                        ? Audition.loc_lang.city : Audition?.loc_lang?.state ? Audition.loc_lang.state : "Any Location"
                    }</div>
                </div>
                {Audition?.specification &&
                    <div className="row auditionList">
                        <div className="apply-data">{Audition.specification}</div>
                    </div>}
            </div>
        </>
    )
}

export { Requirementcontent } 
