import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { AudirequireShare } from './AudirequireShare';
import CustomTop from './CustomTop';
import { Footer, Navbar } from '../Components';
import Slider from 'react-slick';
const AudiShare = () => {
    let { id } = useParams();
    const [Audition, setAudition] = useState([]);
    const [AuditionList, setAuditionlist] = useState([]);
    let Navigate = useNavigate()
    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/audition-requirements/${id}/`);
            setAudition(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const GetDataList = async (profile) => {
        try {
            const response = await axiosConfig.get(`/accounts/audition-requirements/?approval_status=approved&requirement_status=live&page_size=3`);
            setAuditionlist(response.data.results);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetData();
        GetDataList();

    }, []);
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area similar_auditions_container">
                    <div className='similar_auditions_start'>
                        <AudirequireShare Audition={Audition} />
                    </div>
                    <div className='similar_auditions_header'>
                        <h2>Similar Auditions you may intrested in</h2>
                        <button className='btn btn_similar' onClick={() => { Navigate("/auditions") }}>View All</button>
                    </div>
                    <svg width="100%" height="4" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="4" x2="100%" y2="4" stroke="#555555" stroke-width="1" stroke-dasharray="5,5"></line></svg>
                    <div className='similar_auditions_grid'>
                        <div className="row">
                            {AuditionList.map((Audition, index) => (
                                <AudirequireShare Audition={Audition} index={index} />
                            ))}
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <div className='similar_auditions_header'>
                            <button className='btn btn_similar' onClick={() => { Navigate("/auditions") }}>View All</button>
                        </div>
                    </div>

                </div >
            </div >
            <Footer />
        </>
    )
}

export { AudiShare }