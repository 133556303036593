import React from "react";
import "./Post.css"
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineVerified, MdVerified } from "react-icons/md";
import { ActionsDp } from "./ActionsDp";
import { Follow } from "../Follow";
import { BiMap } from "react-icons/bi";
import { useNavigationURLs } from "../../Utils/CheckNavigation";

const PostDP = (props) => {
    const { item } = props;
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const copyToClipboard = async (copy_item) => {
        console.log(item)
        if (copy_item === "profile") {
            try {
                console.log(item);
                await navigator.clipboard.writeText(`${item.full_name} - ${item.designation} - ${calculateAge(item.dob)} years - ${item.state_city.city}(${item.state_city.state})  https://api.hyderabadcastingclub.com/share/profile/${item.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }

    };
    const shareContent = async (share_to) => {
        try {
            let text = `https://api.hyderabadcastingclub.com/share/profile/${item.hcc_id}/`
            const shareUrl = encodeURIComponent(text);

            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `https://api.hyderabadcastingclub.com/share/profile/${item.hcc_id}/`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const { navigateToURL } = useNavigationURLs()
    const SendMessage = (Data) => {
        navigateToURL('messages', Data);
    }
    return (
        <>
            <div className="post-inner-container">
                <div className="posthead">
                    <div style={{ width: '100%', minHeight: '250px' }}>
                        <div className="share-icon">
                            <div className="detial-search">
                                <img src={item && item.profile_pic ? item.profile_pic : require("../Post/images/male.jpg")} className="profile-picture" alt={item?.full_name} />
                                <div>
                                    <div className="d-flex justify-content-space-between align-items-center"><NavLink target="_blank" className="profile-name" to={`/profile/${item.hcc_id}`}>{item.full_name}</NavLink> <p className="post-age">({calculateAge(item.dob)} Y)<span>{item.is_celebrity ? <MdVerified size={12} color="#019bf9" /> : item.is_verified && <MdOutlineVerified size={12} color="#808080" />}</span></p></div>
                                    <p className="hcc_id-post"><NavLink target="_blank" to={`/profile/${item.hcc_id}`}>{item.hcc_id} - {item.designation}</NavLink></p>
                                    {/* <p className="profile-name"><NavLink target="_blank" to={`/profile/${item.hcc_id}`}>{item.full_name}</NavLink>  ({calculateAge(item.dob)} Yrs ) {item.is_celebrity ? <MdVerified size={12} color="#019bf9" /> : item.is_verified && <MdOutlineVerified size={12} color="#808080" />}</p>
                                     */}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <Follow FollowId={item.id} />
                                <div className="dropdown">
                                    <div data-bs-toggle="dropdown">
                                        <MdOutlineMoreVert className="more-icon more_iconmobileres" />
                                    </div>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                        <li><a className="dropdown-item hide-mobile" onClick={() => shareContent("whatsapp")} href="#!">Share to whatsapp</a></li>
                                        <li><a className="dropdown-item hide-mobile" onClick={() => shareContent("fb")} href="#!">Share to facebook</a></li>
                                        <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#!">Share</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="search-border">
                            <div className="profile-pic-div">
                                <NavLink target="_blank" to={`/profile/${item.hcc_id}`}><img src={item && item.profile_pic ? item.profile_pic : require("../Post/images/male.jpg")} className="profile-pic" style={{ borderRadius: '5px' }} alt={item?.full_name} /></NavLink>
                            </div>
                            <div className="profile-cont">
                                <p className="profile-actions">
                                    <ActionsDp item={item} />
                                </p>
                                <div className="profile-title">
                                    {/* <p><NavLink target="_blank" to={`/profile/${item.hcc_id}`}>{item.hcc_id}</NavLink> ({calculateAge(item.dob)} Yrs )</p> */}
                                    {/* <p className="ellipse_container"><BiMap size={16} /><span className="textellipse">{item?.present_city ? item?.present_city : item?.state_city?.city}</span></p> */}
                                    <p className="search-post-count">Posts:<span>({item.total_posts ? item.total_posts : 0})</span></p>
                                    <p className="search-post-count"><MdOutlineRemoveRedEye size={17} /><span style={{ marginLeft: "5px" }}>{item?.views}</span></p>
                                    <div className="message-btn" onClick={() => SendMessage(item)}>Message</div>
                                </div>
                            </div>
                            {item.is_pro && <div className="profile_promember">
                                <img src="/static/media/pro.e75826cf42d2c3a31458.webp" alt="pro-icon" class="menu-icon" color="#daa520" />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export { PostDP };