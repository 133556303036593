import React, { useEffect, useState } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../FormParser/DatePicker';
import { useAuth } from "../../Utils/AuthContext";


const Signup = ({ handleLoginEvent }) => {
  const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft, socketUrl, setSocketurl } = useAuth();
  const [Data, setData] = useState({})
  const [Loading, setLoading] = useState(false)
  const [error, SetError] = useState(null);
  const [popupType, setpopupType] = useState("");
  const [languages, setLanguages] = useState([])
  const [States, setStates] = useState([])
  const [Cities, setCities] = useState([])
  const [formerrorData, SetformErrorData] = useState({})
  const [visible, SetVisable] = useState(false)
  const inputType = visible ? "text" : "password"
  let Navigate = useNavigate()
  const HandleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    console.log(name, value)
    setData({ ...Data, [name]: value })
  }
  const handleMultiSelectChange = (selectedOption, value, name) => {
    console.log(selectedOption, name)
    let data = []
    selectedOption.forEach(res => {
      data.push(res.value)
    })
    setData({ ...Data, [name]: data });
  };
  const handleSelectChange = (selectedOption, name) => {
    console.log(selectedOption, name)
    setData({ ...Data, [name]: selectedOption.value });
    if (name === "state") {
      GetCities(selectedOption.value);
    }
  };
  const genderOptions = [
    "male",
    "female",
    "other"
  ]
  const GetLanguages = async () => {
    const responce = await axiosConfig.get(`/masters/languages/?page_size=1000`)
    console.log(responce)
    setLanguages(responce.data.results)
  }
  const GetStates = async () => {
    const responce = await axiosConfig.get(`/masters/states/?page_size=1000`)
    console.log(responce)
    setStates(responce.data.results)
  }
  const GetCities = async (stateId) => {
    const responce = await axiosConfig.get(`/masters/cities/?page_size=1000&state=${stateId}`)
    console.log(responce)
    setCities(responce.data.results)
  }
  const formSubmit = (event) => {
    event.preventDefault()
    console.log(Data)
    if (Data.mobile.length !== 10) {
      alert("Mobile number should contain exactly 10 digits.");
      return;
    }
    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
    axiosConfig.post("/accounts/api/signup/", Data).then(res => {
      console.log(res)
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('profile_data', JSON.stringify(res.data.profile_data));
      localStorage.setItem('is_pro', res.data.is_pro ? true : false);
      if (res.data.is_24_craft) {
        localStorage.setItem('is_24_craft', res.data.is_24_craft);
        localStorage.setItem('frames_24', res.data.frames_24);
      }
      localStorage.setItem('hcc_id', res.data.hcc_id);
      localStorage.setItem('socketId', socketUrl?.id);
      setpopupType("success");
      handleLoginEvent()
      console.log(res.data.profile_data.profile_pic)
      if (res.data.profile_data.profile_pic === null || "") {
        Navigate(`/profile/${res.data.hcc_id}`)
      }
    }).catch(error => {
      console.log("erros", error.response.data)
      if (error.response) {
        SetError(error.response.data);
        alert(error.response.data)
      }
      SetformErrorData(error.response.data)
      setpopupType("error")
    })
  }
  useEffect(() => {
    GetLanguages()
    GetStates()
    // GetCities()
  }, [])
  return (
    <>
      <div className="container">
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={formSubmit}>
          <div className='row'>
          <div className='login-sign-suggestion'><h2>Create An Account</h2></div>
            <div className="form-group col-md-6">
              <label htmlFor="validationCustom01" >Name:</label>
              <input type="text" className="form-control" id="validationCustom01" placeholder="Name" name="first_name" onChange={HandleChange} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="validationCustom01" >Surname:</label>
              <input type="text" className="form-control" id="validationCustom01" placeholder="Surname" name="last_name" onChange={HandleChange} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="validationCustom01" >Email:</label>
              <input type="email" className="form-control" id="validationCustom01" placeholder="Email" name="email" onChange={HandleChange} required />
            </div>
            <div className="form-group col-md-6 has-icon">
              <label htmlFor="validationCustom01" >Create Password:</label>
              <input type={inputType} className="form-control" id="validationCustom01" placeholder="Create Password" name="password" onChange={HandleChange} required />
              {visible ?
                <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable(false)} /> :
                <img className="inputicon" src={require('../StaffLogin/images/eye.jpg')} alt="password" onClick={() => SetVisable(true)} />
              }
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="validationCustom01" >Mobile Number:</label>
              <input type="tel" className="form-control" id="validationCustom01" placeholder="mobile" name="mobile" onChange={HandleChange} maxLength={10} minLength={10} required onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} />
            </div>
            <div className="form-group col-md-6">
              <label className="form-label">Gender:</label>
              <select className="form-select" name="gender" placeholder='Gender' onChange={HandleChange} required>
                <option value=""></option>
                {
                  genderOptions.map((optionItem, i) => {
                    return (
                      <option value={optionItem} key={i}>{optionItem}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label">State:</label>
              <Select options={States.map((res) => (
                { "value": res.id, "label": res.state_name }
              ))} placeholder="State"
                onChange={(selectedOption) => handleSelectChange(selectedOption, "state")} required />
            </div>
            <div className="form-group col-md-6">
              <label className="form-label">City or Town:</label>
              <Select options={Cities.map((res) => (
                { "value": res.id, "label": res.city_name }
              ))} placeholder="City"
                onChange={(selectedOption) => handleSelectChange(selectedOption, "city")} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="validationCustom01" >Date Of Birth:</label>
              <DatePicker readonly={false} fieldItem={{ fieldName: "dob", required: true }} Data={Data} SetData={setData} />
            </div>
            <div className="form-group col-md-6">
              <label className="form-label">Languages:</label>
              <Select isMulti options={languages.map((res) => (
                { "value": res.id, "label": res.language_name }
              ))} placeholder="Languages"
                onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, "languages")} required />
            </div>
            <div className="form-group form-check">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" name="Terms_conditions" value="true" onChange={HandleChange} required checked />
                I agree to the <a href="/terms-conditions" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
              </label>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export { Signup };