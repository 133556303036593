import { useNavigate } from 'react-router-dom';

export const useNavigationURLs = () => {
    const navigate = useNavigate();

    const navigateToURL = (type, Data) => {
        console.log(type, Data);
        const Messageid = Data?.user?.id;
        if (localStorage.getItem('token')) {
            navigate(`/messages/${Messageid}`, { state: { data: Data } });
        } else {
            document.querySelector('.login-text').click();
        }
    };

    return { navigateToURL };
};

export const copyRequirement = async (data) => {
    try {
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let shareUrl = `${baseUrl}/audition-requirement/${data.id}`;
        await navigator.clipboard.writeText(
            `${data?.character ? `Required ${data.character}` : ""} ${data.audition?.project_type ? `for ${data.audition.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${data.audition?.banner_name ? `- (Banner : ${data.audition.banner_name})` : ""} ${shareUrl}`
        );
        console.log('Text copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy text to clipboard:', err);
    }
};

export const shareRequirement = async (share_to, data) => {
    try {
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let URL = `${baseUrl}/audition-requirement/${data.id}/`;
        let text = `${data?.character ? `Required ${data.character}` : ""} ${data.audition?.project_type ? `for ${data.audition.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${data.audition?.banner_name ? `- (Banner : ${data.audition.banner_name})` : ""} ${URL}`;
        const shareUrl = encodeURIComponent(text);

        if (share_to === "whatsapp") {
            window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
        } else if (share_to === "fb") {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
        } else if (navigator.share) {
            await navigator.share({
                title: 'Share Post',
                text
            });
        }
    } catch (err) {
        console.error('Failed to share content:', err);
    }
};

