import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import axiosConfig from '../Service/axiosConfig';
import { MdLink } from 'react-icons/md';
import { FaArrowLeftLong } from 'react-icons/fa6';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { PopupModal } from '../Components';
import { hasRelevantRole } from '../Utils/RoleCheckService';

const AuditionAcess = ({ show, setShow, AudiData, GetData }) => {
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false);
    const [viewerType, setViewerType] = useState('initial');
    const [selectedData, setSelectedData] = useState(null);
    const [error, setError] = useState('');
    const [formdata, setFormData] = useState({});
    const [updatedAccessControls, setUpdatedAccessControls] = useState({});
    const [Message, setMessage] = useState("")
    const [warning, setwarning] = useState("")
    const AccessChoices = [
        { value: 'owner', label: 'Admin' },
        { value: 'editor', label: 'Editor' },
        { value: 'viewer', label: 'Viewer' },
    ];
    const handleSmallClose = () => {
        setFormData({})
        setSelectedData(null);
        setUpdatedAccessControls({});
        GetData && GetData();
        setShow(false);
    };
    const loadOptions = debounce(async (inputValue, callback) => {
        if (!inputValue) return callback([]);
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/user-profiles/?search_query=${inputValue}&page_size=5`);
            const formattedData = response.data.results.map((item) => ({
                value: item,
                label: (
                    <div className="d-flex align-items-center">
                        <img
                            src={item.profile_pic}
                            alt="profile"
                            className='Access_profile-pic'
                        />
                        <div>
                            <b>
                                {item.full_name} ({item.hcc_id})
                            </b>
                            <p className='post-caption'>{item.user?.email}</p>
                            <p className='post-caption'>{item.designation}</p>
                        </div>
                    </div>
                ),
            }));
            callback(formattedData);
            setLoading(false);
        } catch (error) {
            console.error(error.response?.data?.error || error.message);
            setLoading(false);
            callback([]);
        }
    }, 200);

    const handleSelectChange = (selectedOption, name) => {
        if (hasRelevantRole(selectedOption?.value?.designation)) {
            const profileExists = AudiData?.access_controls?.some(
                (accesscontrol) => accesscontrol?.profile?.id === selectedOption?.value?.id
            );
            if (profileExists) {
                setwarning("This user already has access.");
            } else {
                setSelectedData(selectedOption);
                setFormData({ ...formdata, [name]: selectedOption?.value?.id });
                setViewerType('');
            }
        } else {
            setwarning("You don't have access to share with this user.");
            setSelectedData(null);
        }
    };

    const handleChange = (selectedOption, name, profileId) => {
        setFormData({ ...formdata, profile_id: profileId && profileId, [name]: selectedOption.value });
        setUpdatedAccessControls((prev) => ({
            ...prev,
            [profileId]: selectedOption.value,
        }))
    };
    const ShareAccess = () => {
        if (!formdata.profile_id) {
            setError('Please select a profile');
            return;
        }
        if (!formdata.access_type) {
            setError('Please select an Access Type');
            return;
        }
        const dataToPost = {
            profile_id: formdata?.profile_id,
            access_type: formdata.access_type,
        };
        axiosConfig
            .post(`/accounts/auditions/${AudiData.id}/share/`, dataToPost)
            .then(() => {
                setpopupType("success");
                setMessage("Share Audition Successfully")
                // setShow(false);
                // handleSmallClose();
                // setSelectedData(null);
                // setUpdatedAccessControls({});
            })
            .catch((error) => {
                console.error(error);
                setpopupType("error")
            })
    };

    const copyToClipboard = async (data) => {
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let shareUrl = `${baseUrl}/auditions/${data?.id}/`;
        try {
            await navigator.clipboard.writeText(
                `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""} ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${shareUrl} `
            );
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };
    return (
        <>
            <Modal show={show} onHide={handleSmallClose} size="md" centered>
                <Modal.Body>
                    {viewerType === 'initial' ? (
                        <div className="auditions_access">
                            <h5>Share "Audition"</h5>
                            {error && <div className="alert alert-danger"><FaTimes size={20} />{error}</div>}
                            {warning && <div className="alert alert-warning">{warning}</div>}
                            <div>
                                <AsyncSelect
                                    cacheOptions defaultOptions loadOptions={loadOptions} isLoading={loading}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'profile_id')}
                                    placeholder="Search by name, HCC ID..."
                                    value={selectedData ? { value: selectedData.value, label: selectedData.label } : null}
                                />
                            </div>
                            <div>
                                <div className='people_access mb-2 mt-3'>
                                    <h6>People with access</h6>
                                </div>
                                <div className='access-controls-scroll'>
                                    {AudiData?.access_controls.map((Data, index) => (
                                        <div className='current_user' key={index}>
                                            <div className='d-flex align-items-center mb-3'>
                                                {/* <h6>{Data.profile}</h6> */}
                                                <div className='d-flex align-items-center'>
                                                    <img src={Data.profile?.profile_pic} alt="profile" className='Access_profile-pic' />
                                                    <div>
                                                        {Data.profile?.first_name} ({Data.profile?.hcc_id})
                                                        <p className='post-caption'>{Data.profile?.designation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='access-select'>
                                                <Select
                                                    options={AccessChoices.map((res) => ({ "value": res.value, "label": res.label }))}
                                                    value={AccessChoices.find((option) => option.value === (updatedAccessControls[Data.profile] || Data?.access_type))}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'access_type', Data.profile.id)}
                                                    isDisabled={Data?.access_type === 'owner'}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="current_users mt-4">
                                <button onClick={() => copyToClipboard(AudiData)} className='copy-link'>
                                    <MdLink size={18} />
                                    Copy Link
                                </button>
                                <button onClick={() => ShareAccess()} className='btn btn-primary'>Done</button>
                            </div>
                        </div>
                    ) : (
                        <div className='auditions_access'>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                    <FaArrowLeftLong size={25} style={{ marginRight: '12px' }} onClick={() => { setViewerType('initial'); setError(""); setwarning("") }} />
                                    <h5>Share "Audition"</h5>
                                </div>
                            </div>
                            {error && <div className="alert alert-danger"><FaTimes size={20} />{error}</div>}
                            <div className='d-flex justify-content-between align-items-center'>
                                <div style={{ width: "290px" }}>
                                    <AsyncSelect
                                        cacheOptions defaultOptions loadOptions={loadOptions} isLoading={loading}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'profile_id')}
                                        placeholder="Search by name, HCC ID..."
                                        value={selectedData ? { value: selectedData.value, label: selectedData.label } : null}
                                    />
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Select
                                        options={AccessChoices.map((res) => ({ "value": res.value, "label": res.label }))}
                                        onChange={(selectedOption) => handleChange(selectedOption, 'access_type', selectedData?.value?.id)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                <MdLink size={23} className='icon-link' onClick={() => copyToClipboard(AudiData)} />
                                <div className='d-flex  align-items-center g-4'>
                                    <button className='cancel-button' onClick={handleSmallClose}>Cancel</button>
                                    <button onClick={() => ShareAccess()} className='share-button'>Share Access</button>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={error} HandleEvents={handleSmallClose} successmsg={Message} />
            }
        </>
    );
};

export { AuditionAcess };