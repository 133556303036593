import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { Login } from './Login';
import { Signup } from './Signup';
import { useAuth } from '../../Utils/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginModal = ({ loginShow, setloginShow, loginView, setloginView }) => {
    console.log(loginShow, "setloginshow")
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft, socketUrl, setSocketurl, messagelabel, setMessagelabel } = useAuth();
    const location = useLocation();
    const Navigate = useNavigate();
    const [key, setKey] = useState('Login');
    // const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        // setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            // setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    useEffect(() => {
        // setProfile();
        CheckLogin();
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
                setloginView("")
                setloginShow(false)
            } else {
                console.log("test")
                setloginView("Signup")
                setKey("Signup")
                setloginShow(true)
            }
        }
    }, [])
    useEffect(() => {
        if (loginView === 'login') {
            setKey('Login');
        } else if (loginView === 'Signup') {
            setKey('Signup');
        }
    }, [loginView]);
    return (
        <div>
            <Modal size={key == "login" ? "sm" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton className="login-signup-header" style={{ paddingBottom: "0px" }}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="modal-tabs login-signup-tabs">
                        <Tab eventKey="Login" title="Login"></Tab>
                        <Tab eventKey="Signup" title="Signup"></Tab>

                    </Tabs>
                </Modal.Header>
                <Modal.Body>
                    {key === "Login" ? (
                        <Login handleLoginEvent={handleLoginEvent} />
                    ) : (
                        <Signup handleLoginEvent={handleLoginEvent} />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export { LoginModal } 
