import { useState, useEffect } from 'react';
import axiosConfig from '../Service/axiosConfig';

export const useNotification = () => {
    const [notificationCount, setNotificationCount] = useState(0);
    const profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const fetchNotificationCount = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/user-profiles/${profile_data?.profile_id}/notification_count/`);
            const unreadMessages = response.data?.notifications || 0;
            setNotificationCount(unreadMessages);
        } catch (error) {
            console.error("Error fetching notification count:", error);
        }
    };

    useEffect(() => {
        fetchNotificationCount();

        const intervalId = setInterval(() => {
            fetchNotificationCount();
        }, 300000); // 5 minutes

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [profile_data?.profile_id]);

    return {
        notificationCount,
        fetchNotificationCount
    };
};
