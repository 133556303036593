import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { Post } from "../Components";

const PostsPage = ({ footerRef }) => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;
    let endpoint = '/accounts/model-images/?is_story=false&is_active=true'
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`${endpoint}`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    console.log(window, "scroll", window.innerHeight + document.documentElement.scrollTop + 5 >= document.documentElement.scrollHeight, isMobile)
    console.log(isMobile, "scroll", window.innerHeight, document.documentElement.scrollTop + 10, window.innerHeight + document.documentElement.scrollTop + 10, document.documentElement.scrollHeight)
    const handleInfiniteScroll = async () => {
        if (!footerRef?.current) return;

        const footerPosition = footerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (footerPosition - windowHeight < 552 && !loading2) {
            setLoading2(true);
            try {
                page += 1;
                const response = await axiosConfig.get(`/accounts/model-images/?page=${page}`);
                SetData((prevData) => [...prevData, ...response.data.results]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading2(false);
            }
        }
    };

    useEffect(() => {
        GetData();
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            {
                <div className="products-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('./images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {Data.length > 0 &&
                                <div className="post-data">
                                    {
                                        Data.map((itemObj, index) => {
                                            return (
                                                <div className="post-item" key={index}>
                                                    <Post item={itemObj} index={index} />
                                                </div>
                                            )
                                        })
                                    }
                                    {loading2 && (
                                        <div className="loader-container">
                                            <img src={require('./images/loader.gif')} alt="Loading..." />
                                        </div>
                                    )}
                                </div>
                            }
                        </>
                    )}
                </div>
            }
        </>
    )
}
export { PostsPage };