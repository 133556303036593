import React, { useState, useEffect,  useRef} from "react";
import { Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AudiRequire } from "./AudiRequire";

const AuditionPage = () => {
     const footerRef = useRef(null);
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page mobileauditions_bacgroung">
                <Navbar />
                <div className="content-area">
                    <AudiRequire footerRef={footerRef}/>
                </div>
            </div >
            <Footer  ref={footerRef}/>
        </>
    );
}

export { AuditionPage };
