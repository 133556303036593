import React, { useEffect, useState } from 'react'
import { AlertDeletePopupModal, DeletePopupModal, PopupModal } from '../PopupModal'
import { useNavigate } from 'react-router-dom'
import axiosConfig from "../../Service/axiosConfig"
import { Modal } from 'react-bootstrap'
const DeleteAccountRequest = ({ DeleteAlert, setDeleteAlert }) => {
    const [DeletePopup, setDeletePopup] = useState("")
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    let Navigate = useNavigate()
    const CancelAlerrt = () => {
        setDeleteAlert("error")
    }
    const HandleDeleteClose = () => {
        setDeleteAlert("error")
    }
    const HandleDelete = () => {
        axiosConfig.post("/accounts/profile-restriction/", { restriction_type: "delete", profile: profile_data?.profile_id })
            .then(res => {
                setDeletePopup("success")
            }).catch(error => {
                console.log(error)
            })
    }
    return (
        <div>
            {DeletePopup !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletePopup} deletePopupType={DeletePopup} message={"Your account deletion request has been accepted. The account will be deleted within 48 hours."} HandleEvents={HandleDeleteClose} />
            }
            {DeleteAlert === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={DeleteAlert} SetAlertDeletePopupType={setDeleteAlert} CancelAlerrt={CancelAlerrt} HandleDelete={HandleDelete} message={"Are You Sure You Want Delete Your Account ?"} />
            }
        </div>
    )
}
const ChangePasswordRequest = ({ PasswordAlert, setPasswordAlert }) => {
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [visible, SetVisable] = useState("")
    const [Data, setData] = useState("")
    const [popuptype, setpopuptype] = useState("")
    const inputTypenew = visible === "new_password" ? "text" : "password";
    const inputTypeconfirm = visible === "confirm_password" ? "text" : "password";
    const [error, setError] = useState("");
    const [message, setMessage] = useState("")
    const [passwordmodal, setpasswordmodal] = useState(false)
    const closeModal = () => {
        setPasswordAlert(false);
        setpasswordmodal(false)
        setError("");
        setData({});
    };
    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({ ...Data, [name]: value })
    }
    const validatePasswords = () => {
        if (!Data.new_password) {
            setError("New Password is required");
            return false;
        }
        if (!Data.confirm_password) {
            setError("Confirm Password is required");
            return false;
        }
        if (Data.new_password !== Data.confirm_password) {
            setError("Passwords do not match");
            return false;
        }
        setError("");
        return true;
    };
    const HandleChangePassword = async () => {
        if (!validatePasswords()) return;
        try {
            const response = await axiosConfig.post(`/accounts/user-profiles/${profile_data.profile_id}/change_password/`, { password: Data.new_password })
            setMessage(response?.data)
            setpopuptype("success")
            setpasswordmodal(false)
        }
        catch (error) {
            setPasswordAlert(true);
            console.log(error);
        }
    };
    useEffect(() => {
        if (PasswordAlert) {
            setpasswordmodal(true)
        } else {
            setpasswordmodal(false)
        }
    }, [PasswordAlert])
    return (
        <>
            <div>
                <Modal size='xs' aria-labelledby="Change-password-modal-title" centered show={passwordmodal} onHide={closeModal} className="password-modal">
                    <Modal.Body>
                        <div className='change-password-content'>
                            <h5>Change Password</h5>
                            <div className="material-textfield">
                                <input type={inputTypenew} id="newPassword" placeholder="New password" name="new_password" onChange={handleInputChange} required={Data.new_password === ""} />
                                <label htmlFor="newPassword" >New Password</label>
                                {visible === "new_password" ?
                                    <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable("")} /> :
                                    <img className="inputicon" src={require('../StaffLogin/images/eye.jpg')} alt="password" onClick={() => { SetVisable("new_password") }} />
                                }
                            </div>
                            <div className="material-textfield">
                                <input type={inputTypeconfirm} id="confirmPassword" placeholder="Confirm password" name="confirm_password" onChange={handleInputChange} required={Data.confirm_password === ""} />
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                {visible === "confirm_password" ?
                                    <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => { SetVisable("") }} /> :
                                    <img className="inputicon" src={require('../StaffLogin/images/eye.jpg')} alt="password" onClick={() => { SetVisable("confirm_password") }} />
                                }
                            </div>
                            {error && <p className='alert alert-danger'>{error}</p>}
                            <button type="submit" className="btn btn-primary btn-block" onClick={HandleChangePassword}>
                                Change Password
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
            {popuptype !== "" &&
                <PopupModal setpopupType={setpopuptype} popupType={popuptype} successmsg={message} HandleEvents={() => { closeModal(); }} />
            }
        </>
    );
};

export { DeleteAccountRequest, ChangePasswordRequest } 
