import axiosConfig from '../Service/axiosConfig';

export const closeRequirement = async (Audi_id, statustype, setpopupType, setMessage, additionalCallback) => {
    const data = {
        requirement_status: statustype,
        approval_status: "approved"
    };
    try {
        const response = await axiosConfig.patch(`/accounts/audition-requirements/${Audi_id}/`, data);
        setpopupType("success");
        setMessage(`Audition Requirement ${statustype} Successfully!`);
        additionalCallback && additionalCallback();
        return response;
    } catch (error) {
        console.error("Error closing requirement:", error);
        throw error;
    }
};
