import React, { useEffect, useState } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import DatePicker from '../FormParser/DatePicker';

const EditProfile = ({ ProfileData, handleLoginEvent}) => {
  const [Data, setData] = useState({})
  console.log(ProfileData)
  const OnSubmit = (e) => {
    e.preventDefault()

    // Handle signup logic here
    console.log('Signup button clicked!');
    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
    axiosConfig.post("/accounts/api/editprofile/", Data).then(res => {
      console.log(res)
      handleLoginEvent()
     
    }).catch(error => {
      console.log(error.response.data.error)
    })


  };



  const handleInputChange = (e) => {
    e.preventDefault();
    const name = e.target.name
    const value = e.target.value
    console.log(name, value)
    setData({ ...Data, [name]: value })
  }
  const HandleFiles = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    const name = e.target.name;
    const value = file;
    console.log(name, value);
    setData({ ...Data, [name]: value })
  }
  console.log(Data)
  return (
    <div className="container">
      <form onSubmit={OnSubmit} >
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Surname:</label>
              <input
                type="text"
                className="form-control"
                required
                name="last_name"
                onChange={handleInputChange}
                defaultValue={ProfileData.user.last_name}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                className="form-control"
                required
                name="first_name"
                onChange={handleInputChange}
                defaultValue={ProfileData.user.first_name}
              />
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Email:</label>
              <input
                type="text"
                className="form-control"
                required
                name="email"
                onChange={handleInputChange}
                defaultValue={ProfileData.user.email}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Mobile:</label>
              <input
                type="text"
                className="form-control"
                required
                name="mobile"
                onChange={handleInputChange}
                defaultValue={ProfileData.mobile_number}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Gender:</label>
              <select
                className="form-control"
                required
                name="gender"
                onChange={handleInputChange}
              >
                <option value="male">Male</option>
                <option value="female" selected={ProfileData.gender === "female"} >Female</option>
                <option value="other" selected={ProfileData.gender === "other"}>Other</option>
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>D.O.B. :</label>
               <DatePicker readonly={false} fieldItem={{fieldName:"dob",required:false}} Data={ProfileData}  SetData={setData}/>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label>Profession:</label>
              <input
                type="text"
                className="form-control"
                name="profession"
                onChange={handleInputChange}
                defaultValue={ProfileData.profession}

              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          Update Profile
        </button>
      </form>
    </div>
  );
};

export { EditProfile };
