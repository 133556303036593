import React from "react";
import "../Components/Navbar/Navbar.css"
import { PostDP } from "../Components";
import  { useState, useEffect } from "react";

const ExploreGrid = ({ Data, loading, loading2 }) => {
    const [columns, setColumns] = useState("col-lg-4 col-xl-3");
    const updateColumns = () => {
        const width = window.innerWidth;
        if (width >= 1200 && width <= 1460) {
          setColumns("col-lg-4");
        } else if (width > 1460) {
          setColumns("col-xl-3");
        } else {
          setColumns("col-lg-6 col-md-6 col-sm-12");
        }
      };
      useEffect(() => {
        window.addEventListener("resize", updateColumns);
        updateColumns(); // Initial check
        return () => window.removeEventListener("resize", updateColumns);
      }, []);
    return (
        <>
            <div className="products-container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        {Data.length > 0 ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        {
                                            Data.map((itemObj, index) => {
                                                return (
                                                    <div className={`${columns}`} key={index}>
                                                        <div className="search-container">
                                                            <PostDP item={itemObj} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {loading2 && (
                                        <div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                        </div>
                                    )}
                                </div>
                            </>
                            :
                            <>
                                <p className="nosearchresults">Search with new keyword/ filters</p>
                            </>
                        }
                    </>
                )}
            </div>
        </>
    )
}
export { ExploreGrid };