import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { Follow } from '../Components';
import { MdOutlineLocalPhone } from 'react-icons/md';

const ContactModal = ({ AudiData, show, setShow }) => {
    const Navigate = useNavigate();
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            Navigate(`/profile/${link}`)
        }
        if (type === "back") {
            Navigate(-1)
        }
    }
    const handleSmallClose = () => setShow(false);
    const SendMessage = (Data) => {
        let id = Data?.profile?.user_id
        Navigate(`/messages/${id}`, { state: { data: Data?.profile } })
    }
    return (
        <>
            <Modal show={show} onHide={handleSmallClose} vsize="md" centered className="contactdetails_model_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Contact Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='contact_profileheader'>
                                        <div className="contact_propfileinfor">
                                            <div className="contact_profile_right">
                                                <img src={AudiData.profile?.profile_pic ? AudiData.profile?.profile_pic : require('../Components/Post/images/male.jpg')} onClick={() => handleNavigate(AudiData.profile?.hcc_id, "profile")} className="dp" alt={AudiData.profile?.first_name} />
                                            </div>
                                            <div className="contact_profile_title">
                                                {AudiData.profile?.first_name && <h5 onClick={() => handleNavigate(AudiData.profile?.hcc_id, "profile")}>{AudiData.profile?.first_name}</h5>}
                                                {AudiData.profile?.first_name && <h6>{AudiData.profile?.hcc_id}</h6>}
                                                {AudiData.profile?.designation && <h6>{AudiData.profile?.designation}</h6>}
                                            </div>
                                        </div>
                                        <Follow FollowId={AudiData?.profile?.id} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Name</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser text-overflow">
                                                {AudiData.contact_name && <span>{AudiData.contact_name}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Designation</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser text-overflow">
                                                {AudiData.contact_name && <span> {AudiData.contact_designation}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Mobile No</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser text-overflow">
                                                {AudiData.contact_name && <span>{AudiData?.contact_mobile_no}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    {AudiData.contact_mail && <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Email</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser text-overflow">
                                                <span>{AudiData?.contact_mail}</span>
                                            </div>
                                        </Col>
                                    </Row>}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col div className="contact_shareheader justify-content-between">
                                <div div className="message-btn" onClick={() => SendMessage(AudiData)}>
                                    <img src={require("./images/message.png")} width={20} alt="profile" />  Message
                                </div>
                                <Col div className="contact_button" onClick={handleSmallClose}>
                                    <a href={`tel:${AudiData?.contact_mobile_no}`}><MdOutlineLocalPhone size={20} /> Call Now</a>
                                </Col>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { ContactModal }
